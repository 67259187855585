.data-counts {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  flex-wrap: wrap;
  gap: 10px;
}

.data-count-item {
  flex: 1;
  text-align: center;
  padding: 15px;
  border-top: 3px solid var(--slate-300);
  border-bottom: 3px solid var(--slate-300);
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: var(--bg-default);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.data-count-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.data-count-value {
  font-size: 36px;
  font-weight: bold;
  color: var(--primary);
  margin: 10px 0 0 0;
}

/* Container principal para a navegação de mês */
.month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: transparent var(--secondary) transparent var(--secondary) !important;
  border: 4px solid;
  background-color: var(--bg-default);
}

/* Estilização dos botões de navegação */
.nav-btn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary);
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-btn:hover {
  transform: scale(1.1);
}

.nav-btn:focus {
  outline: none;
  border: none;
}

.nav-btn:active {
  color: var(--primary);
  transform: scale(1);
}

/* Ícones à esquerda e direita */
.nav-btn i {
  font-size: 24px;
  margin-right: 10px;
}

.btn-left i {
  margin-right: 10px;
}

.btn-right i {
  margin-left: 10px;
}

/* Estilo do nome do mês */
.month-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--text-color);
}

.recharts-default-legend {
  height: 150px;
  overflow: auto;
  justify-content: start;
  align-items: start;
  border-radius: var(--br-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--slate-300);
  padding: 0.5rem !important;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.recharts-legend-item {
  text-align: start;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.toggle-buttons button {
  background: var(--slate-50);
  border: none;
  padding: 10px 15px;
  border-radius: var(--br-md);
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-buttons button.active {
  background: var(--secondary);
  color: var(--white);
  box-shadow: 0 0 8px rgba(21, 101, 192, 0.8);
  transform: scale(1.1);
}

.toggle-buttons button:hover {
  background: #90caf9;
}

.content {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .month-navigation-title {
    display: none;
  }
}
