body.dark {
  --color-text: #f1f5f9;
  /* Cor do texto no tema escuro */
  --secondary: #1a91ff;
  /* Cor secundária no tema escuro */
  --bg-default: #0f172a;
  /* Cor de fundo principal escura */
  --bg-secondary: #1e293b;
  /* Cor de fundo secundária escura */
  --bg-tertiary: #334155;
  /* Cor terciária escura */
  --slate-300: #475569;
  /* Cor intermediária para "slate" no tema escuro */
  --bg: #020617;
  /* Cor de fundo geral para o body */
  --shadow-inside: none;
  /* Sem sombras internas no tema escuro */
  --white: #fff;
  --black: #000;
}

.dark .header {
  box-shadow: none;
  background-color: var(--slate-900);
}

.dark .sidebar {
  box-shadow: none;
}