/* Estilos gerais para o Header */
.header {
  width: 100%;
  top: 0;
  left: 0;
  color: var(--white);
  background-color: var(--slate-900);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--slate-300);
  position: sticky;
  z-index: 99;
  box-shadow: 5px 5px 10px #dcdcdc;
}

.header__right {
  display: flex;
  gap: 10px;
  padding: 0.5rem 1rem;
}

.header__container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  padding: 0rem 1rem;
}

/* Estilo do botão de menu (Sidebar Toggle) */
.header__menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
}

/* Controles de tema */
.header__actions_controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actions__button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--slate-300);
  border-radius: var(--br-md);
  background-color: var(--bg-default);
  color: var(--color-text-secondary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.actions__button:hover {
  color: white;
  background-color: var(--slate-800);
}

/* Link de ajuda */
.header__help {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #0056b3;
  text-decoration: none;
  font-weight: 500;
}

.header__help:hover {
  text-decoration: underline;
}

/* Perfil do usuário */
.header__profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header__profile-name {
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 140px;
  white-space: nowrap;
  overflow-x: hidden;
}

.header__profile-name::before {
  content: "";
  height: 100%;
  width: 40px;
  position: absolute;
  background: linear-gradient(90deg, transparent, var(--slate-900));
  right: 0;
}

.header__logo {
  display: block;
  width: 140px;
}

.navigation__menu {
  color: var(--white) !important;
}

/* Ajuste no Header para telas menores */
@media (max-width: 768px) {
  .header {
    position: relative;
  }

  .header__right {
    justify-content: space-between;
    width: 100%;
  }

  .header__profile-name {
    max-width: 180px;
  }

  .header__logo {
    display: none;
  }
}
