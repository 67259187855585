.header__container-c {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
}

.header__profile-name-c {
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
}

.header__profile-name-c::before {
  content: "";
  height: 100%;
  width: 40px;
  position: absolute;
  background: linear-gradient(90deg, transparent, var(--slate-900));
  right: 0;
}
