.table > tbody > tr > td {
  vertical-align: middle;
  text-align: center;
  border: 0;
}

.table > tbody > tr:nth-child(2n) {
  background: var(--bg-secondary) !important ;
 }

.table > tbody > tr:hover {
 background: var(--bg-tertiary);
}

.table > thead > tr > th {
  outline: none;
}

.react-bootstrap-table-pagination-total {
  font-size: 1rem !important;
  font-weight: bold;
}

