.navigation__menu {
  color: var(--white) !important;
}

.navigation__link,
.navbar-light .navbar-nav .nav-link {
  color: var(--color-text-secondary);
  position: relative;
}

.navigation__link:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--white) !important;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: var(--white) !important;
}

.active__link {
  color: var(--white) !important;
}

.active__link::before {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  background-color: var(--primary);
  bottom: -18px;
  left: 0;
}

.navbar button {
  background: var(--bg-secondary) !important;
  color: var(--color-text) !important;
  border: 1px solid var(--slate-300) !important;
}

.navigation__responsive {
  display: none;
  position: sticky;
  top: 0;
  z-index: 99;
}

.navigation__hidden {
  display: block;
}

/* Ajuste no Header para telas menores */
@media (max-width: 768px) {
  .navigation__hidden {
    display: none;
  }

  .navigation__responsive {
    display: block;
    background: var(--bg-secondary);
    border-bottom: 1px solid var(--slate-300);
  }

  .active__link::before {
    height: 100%;
    position: absolute;
    width: 4px;
    left: -10px;
    bottom: 0;
  }

  .active__link {
    color: var(--color-text) !important;
  }
}
