.icon-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;
  height: 60px;
  padding: 0.5rem;
  background: var(--white);
  border-radius: var(--br-md);
  border: 1px solid var(--slate-300);
}

.profile-icon {
  cursor: pointer;
  z-index: 1;
}

.icon-img img,
.profile-icon img {
  width: 40px;
}

.edit-dropdown {
  position: absolute;
  bottom: 0;
  right: -15px;
  padding: 0.2rem;
  background: var(--white);
  border: 1px solid var(--slate-300);
  border-radius: var(--br-md);
}
